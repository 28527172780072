import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="about-container">
      <h2>About</h2>
      <p>Based in Los Angeles,</p>
      <p>Nowheregirl Studio captures your unique personality in nostalgic, retro yearbook-style portraits.</p>
      <p>Diverse backdrops transform each ID shot into a canvas for your unexpressed self.</p>
      <p>Inspired by the timeless "school uniform" era,</p>
      <p>Let's celebrate the audacious, the spirited, and the original in every shot.</p>
      <p>Email: stellazhangx@gmail.com</p>
    </div>
  );
}

export default About;
