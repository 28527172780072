import React from 'react';
import './MobilePricingPage.css';

const MobilePricingPage = () => {
    return (
        <div className="mobile-pricing-page">
            <img src="https://storage.googleapis.com/portfolio-nowheregirl/pricing.jpg" alt="Pricing" className="pricing-image" />
        </div>
    );
};

export default MobilePricingPage;
