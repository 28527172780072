import React from 'react';
import './Portrait.css';

const Portrait = () => {
  return (
    <div className="portrait-container">
      <img src="https://storage.googleapis.com/portfolio-nowheregirl/Portrait/grace.jpg" alt="grace"/>
      <img src="https://storage.googleapis.com/portfolio-nowheregirl/Portrait/renee.jpg" alt="renee"/>
      <img src="https://storage.googleapis.com/portfolio-nowheregirl/Portrait/zao.jpg" alt="steph"/>
    </div>
  );
};

export default Portrait;
