import React, { useEffect, useState } from 'react';
import './ReservationMobile.css';  // Assuming you'll have a separate CSS file for mobile
import { useNavigate } from 'react-router-dom';
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const getDaysInMonth = (date) => {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
};  

const ReservationMobile = () => {
    const today = new Date(); 
    const [currentDate, setCurrentDate] = useState(today); 
    const [selectedDate, setSelectedDate] = useState(today);
    const [availableTimes, setAvailableTimes] = useState({});
    const navigate = useNavigate();

  useEffect(() => {
    const fetchAvailability = async () => {
      try {
        const response = await fetch(`${BACKEND_URL}/api/availability?month=${currentDate.getMonth() + 1}&year=${currentDate.getFullYear()}`);
        const data = await response.json();

        const newAvailableTimes = {};
        data.forEach((entry) => {
          if (entry.date && entry.slots.length > 0) {
            newAvailableTimes[entry.date] = entry.slots;
          } else if (entry.date) {
            newAvailableTimes[entry.date] = ["Unavailable"];
          }
        });

        setAvailableTimes(newAvailableTimes);
      } catch (error) {
        console.error('Error fetching available times:', error);
      }
    };
    
    fetchAvailability();
  }, [currentDate]);

  const goToPreviousMonth = () => {
    const newDate = new Date(currentDate.setMonth(currentDate.getMonth() - 1));
    setCurrentDate(newDate);
    setSelectedDate(new Date(newDate.getFullYear(), newDate.getMonth(), 1));
  };
  
  const goToNextMonth = () => {
    const newDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
    setCurrentDate(newDate);
    setSelectedDate(new Date(newDate.getFullYear(), newDate.getMonth(), 1));
  };
  
  const bookSlot = (time) => {
    const dateKey = `${selectedDate.getFullYear()}-${String(selectedDate.getMonth() + 1).padStart(2, '0')}-${String(selectedDate.getDate()).padStart(2, '0')}`;
    navigate(`/reservation/booking/${dateKey}/${time}`);
  };

  const selectDate = (date) => {
    setSelectedDate(date);
  };



  const renderDays = () => {
    const daysInMonth = getDaysInMonth(currentDate);
    const days = [];
  
    if (today.getMonth() === currentDate.getMonth() && today.getFullYear() === currentDate.getFullYear()) {
      for (let i = today.getDate(); i <= daysInMonth; i++) { 
        const dayDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), i);
        const isSelected = dayDate.getDate() === selectedDate.getDate() &&
          dayDate.getMonth() === selectedDate.getMonth() &&
          dayDate.getFullYear() === selectedDate.getFullYear();
    
        days.push(
          <div
            className={`mobile-day ${isSelected ? 'selected' : ''}`}
            key={`day-${i}`}
            onClick={() => selectDate(dayDate)}
          >
            <div>{i}</div>
          </div>
        );
      }
    } else {
      for (let i = 1; i <= daysInMonth; i++) {
        const dayDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), i);
        const isSelected = dayDate.getDate() === selectedDate.getDate() &&
          dayDate.getMonth() === selectedDate.getMonth() &&
          dayDate.getFullYear() === selectedDate.getFullYear();
    
        days.push(
          <div
            className={`mobile-day ${isSelected ? 'selected' : ''}`}
            key={`day-${i}`}
            onClick={() => selectDate(dayDate)}
          >
            <div>{i}</div>
          </div>
        );
      }
    }
  
    return days;
  };


  const timesForSelectedDate = availableTimes[`${selectedDate.getFullYear()}-${String(selectedDate.getMonth() + 1).padStart(2, '0')}-${String(selectedDate.getDate()).padStart(2, '0')}`] || ["Unavailable"];

return (
    <div className="reservation-mobile-container">
      <div className='calendar-mobile-container'>
        <div className="calendar-mobile-header">
          <button onClick={goToPreviousMonth}>&lt;</button>
          <span>{`${months[currentDate.getMonth()]} ${currentDate.getFullYear()}`}</span>
          <button onClick={goToNextMonth}>&gt;</button>
        </div>

        <div className="days-container-mobile">
          {renderDays()}
        </div>

        <div className="selected-date">
          {`${selectedDate.getFullYear()}-${String(selectedDate.getMonth() + 1).padStart(2, '0')}-${String(selectedDate.getDate()).padStart(2, '0')}`}
        </div>

        <div className="available-times-mobile">
          {timesForSelectedDate.map((time, index) => (
            <div 
              key={index} 
              className={time !== "Unavailable" ? 'clickable-time-mobile' : 'non-clickable-time-mobile'}
              onClick={() => {
                if (time !== "Unavailable") {
                  bookSlot(time);
                }
              }}
            >
              {time}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

// ...


export default ReservationMobile;