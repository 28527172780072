import React from 'react';
import './Yearbook.css';

const Yearbook = () => {
  return (
    <div className="yearbook-container">
      <img src="https://storage.googleapis.com/portfolio-nowheregirl/Yearbook/hyc.jpg" alt="1"/>
      <img src="https://storage.googleapis.com/portfolio-nowheregirl/Yearbook/xi.jpg" alt="2"/>
      <img src="https://storage.googleapis.com/portfolio-nowheregirl/Yearbook/4grid.JPG" alt="3"/>
      <img src="https://storage.googleapis.com/portfolio-nowheregirl/Yearbook/Violet.JPG" alt="4"/>
      <img src="https://storage.googleapis.com/portfolio-nowheregirl/Yearbook/shidading.JPG" alt="5"/>
      <img src="https://storage.googleapis.com/portfolio-nowheregirl/Yearbook/couple.jpg" alt="6"/>
      <img src="https://storage.googleapis.com/portfolio-nowheregirl/Yearbook/piano.jpg" alt="7"/>
      <img src="https://storage.googleapis.com/portfolio-nowheregirl/Yearbook/Alice.JPG" alt="8"/>
      
    </div>
  );
};

export default Yearbook
