import React from 'react';
import './LinkedInPhoto.css';

const LinkedInPhoto = () => {
  return (
    <div className="linkedinphoto-container">
      <img src="https://storage.googleapis.com/portfolio-nowheregirl/Headshot/stella.jpg" alt="stel"/>
    </div>
  );
};

export default LinkedInPhoto
