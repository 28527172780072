import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.css';

function Sidebar() {
  const [isOpen, setIsOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const closeSidebar = () => {
    setIsOpen(false);
  };

  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <div className="sidebar-item">
        <h2><Link to="/" onClick={closeSidebar}>Nowheregirl Studio</Link></h2>
      </div>
      <div className="mobile-menu-icon" onClick={toggleSidebar}>
        {isOpen ? 'x' : (
          <div className="hamburger-icon">
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
        )}
      </div>

      <div className={`mobile-sidebar-content ${isOpen ? 'open' : ''}`}>
        <div className="sidebar-item">
          <span>Portfolio</span>
          <ul className="sidebar-submenu">
            <li><Link to="/portfolio/portrait" onClick={closeSidebar}>Portrait</Link></li>
            <li><Link to="/portfolio/yearbook" onClick={closeSidebar}>Yearbook</Link></li>
            <li><Link to="/portfolio/linkedin-photo" onClick={closeSidebar}>LinkedIn Photo</Link></li>
          </ul>
        </div>
        <div className="sidebar-item">
          <Link to="/reservation" onClick={closeSidebar}>Reservation</Link>
        </div>
        {windowWidth <= 768 && (
          <div className="sidebar-item">
            <Link to="/pricing" onClick={closeSidebar}>Pricing</Link>
          </div>
        )}
        <div className="sidebar-item">
          <Link to="/about" onClick={closeSidebar}>About</Link>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
