import React from 'react';
import Reservation from './Reservation';
import ReservationMobile from './ReservationMobile';
import './ResponsiveReservation.css';

const ResponsiveReservation = () => {
  return (
    <div className="responsive-reservation">
      <div className="desktop">
        <Reservation />
      </div>
      <div className="mobile">
        <ReservationMobile />
      </div>
    </div>
  );
};

export default ResponsiveReservation;
