import React, { useState, useEffect, useCallback } from 'react';
import ReservationList from './ReservationList';
import AvailabilityList from './AvailabilityList';
import './Dashboard.css';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const Dashboard = () => {
  const [adminSecret, setAdminSecret] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [reservations, setReservations] = useState([]);
  const [availability, setAvailability] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [isAddingAvailability, setIsAddingAvailability] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      const res1 = await fetch(`${BACKEND_URL}/api/reservations`, {
        headers: {
          'x-admin-secret': adminSecret,
        },
      });
      const data1 = await res1.json();
      // Assuming reservations also have a date field to sort by
      const sortedReservations = data1.sort((a, b) => a.date.localeCompare(b.date));
      setReservations(sortedReservations);

      const res2 = await fetch(`${BACKEND_URL}/api/availability`, {
        headers: {
          'x-admin-secret': adminSecret,
        },
      });
      const data2 = await res2.json();
      const sortedAvailability = data2.sort((a, b) => a.date.localeCompare(b.date));
      // Sort the slots within each date
      sortedAvailability.forEach(item => {
        item.slots.sort((a, b) => a.localeCompare(b));
      });
      setAvailability(sortedAvailability);
      
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  }, [adminSecret]);

  // Refresh data function
  const refreshData = useCallback(() => { 
    if (isAuthenticated && adminSecret) {
      fetchData();
    }
  }, [isAuthenticated, adminSecret, fetchData]);  // <-- Add necessary dependencies here
  

  useEffect(() => {
    refreshData();
  }, [refreshData]);


  const checkAuthentication = () => {
    fetch(`${BACKEND_URL}/admin`, {
      headers: {
        'x-admin-secret': adminSecret,
      },
    })
    .then(response => {
      console.log('Raw response:', response);  // log the raw response
      if(response.status === 403) {
        alert('Forbidden: Incorrect Admin Secret');
        setIsAuthenticated(false);
        return null; // Returning null so that the next .then() block won't execute
      } else if(response.status === 200 && response.headers.get('Content-Type').includes('application/json')) {
        setIsAuthenticated(true);
        return response.json();  // Assuming your /admin API returns JSON
      } else {
        // Handle other status codes and content types as necessary
        console.log(`Received unexpected status code ${response.status} or content type ${response.headers.get('Content-Type')}`);
        return null;
      }
    })
    .then(data => {
      if(data) {
        // Handle the fetched data here
        console.log(data);
      }
    })
    .catch(error => console.log('Error:', error));
  };
  

  const updateReservations = (updatedReservations) => {
    setReservations(updatedReservations);
  };

  const updateAvailability = (updatedAvailability) => {
    setAvailability(updatedAvailability);
  };

  return (
    <div className='container'>
      {!isAuthenticated ? (
        <div className='admin-login'>
          <h1>Admin Login</h1>
          <input
            type="password"
            placeholder="Enter Admin Secret"
            value={adminSecret}
            onChange={e => setAdminSecret(e.target.value)}
          />
          <button onClick={checkAuthentication}>Authenticate</button>
        </div>
      ) : (
        <div className='admin-dashboard'>
          <h1>Admin Dashboard</h1>
          <button className="refresh-button" onClick={refreshData}>Refresh</button>
          <div className="table-header">
            <h2>Reserved Time Slots</h2>
          </div>
          <ReservationList 
            adminSecret={adminSecret} 
            reservations={reservations}
            updateReservations={updateReservations}
            isAdding={isAdding} 
            setIsAdding={setIsAdding} 
          />
          <div className="table-header">
            <h2>Available Time Slots</h2>
          </div>
          <AvailabilityList 
            adminSecret={adminSecret} 
            availability={availability}
            updateParentAvailability={updateAvailability}
            setAvailability={setAvailability}
            isAddingAvailability={isAddingAvailability}
            setIsAddingAvailability={setIsAddingAvailability}
          />
        </div>
      )}
    </div>
  );
};

export default Dashboard;
